import { Link } from 'gatsby';
import React from 'react';

const Footer = (props) => (
  <footer className="footer-container">
    {props.smartfeedCompanyData &&
      props.smartfeedCompanyData.CompanyName &&
      props.smartfeedCompanyData.CompanyName !== 'DefaultCompany' && (
        <div className="footer-register-wrap">
          {props.apiKey && !props.jobAlertsPage && props.displayJACTA && (
            <div className="footer-register-container container">
              <div className="vf-careers-site-spacing-cta">
                <article aria-label="Subscribe to job alerts">
                  <div className="ctacard soft">
                    <div className="ctacard__body d-flex justify-content-center">
                      <div className="col-md-10 col-lg-6 text-center">
                        <h4 className="ctacard__body__title">
                          <span>Can't find a job role to suit you?</span>
                        </h4>
                        <p className="ctacard__body__summary">
                          Get job alerts straight to your inbox and be the first
                          to see our new vacancies.
                        </p>
                        <Link
                          to={
                            props.useGroupVacsLink === true
                              ? '/job-alerts/group/'
                              : `/job-alerts/`
                          }
                          className="btn--action"
                        >
                          Subscribe to job alerts
                        </Link>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          )}
        </div>
      )}
    <div className="footer-attribution-wrap">
      <div className="footer-attribution-container container">
        <div className="left text-center text-lg-left">
          <span className="accessibility-selectors">
            <ul>
              <li className="fontSize_Selection">
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_normal"
                  alt="Normal Font Size"
                  title="Normal Font Size"
                  onClick={() => props.setFontSize('normal')}
                >
                  <span className="fal fa-font"></span>
                </button>
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_medium"
                  alt="Medium Font Size"
                  title="Medium Font Size"
                  onClick={() => props.setFontSize('medium')}
                >
                  <span className="fal fa-font"></span>
                </button>
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_large"
                  alt="Large Font Size"
                  title="Large Font Size"
                  onClick={() => props.setFontSize('large')}
                >
                  <span className="fal fa-font"></span>
                </button>
              </li>
              <li className="contrast_Selection">
                {props.colourContrast ? (
                  <button
                    className="contrast_trigger contrast_trigger_light"
                    alt="Toggle Dark Mode"
                    title="Toggle Dark Mode"
                    onClick={() => props.setColourContrast(false)}
                  >
                    <span className="fas fa-adjust"></span>
                  </button>
                ) : (
                  <button
                    className="contrast_trigger contrast_trigger_dark"
                    onClick={() => props.setColourContrast(true)}
                  >
                    <span className="fad fa-adjust"></span>
                  </button>
                )}
              </li>
            </ul>
          </span>
        </div>

        <div className="right text-center text-lg-right">
          {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
            <>
              <a
                href={props.appGatewayURL.ApplicantGateway}
                target="_blank"
                rel="noreferrer"
              >
                Applicant Gateway
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
