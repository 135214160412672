import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';

import Dropdown from '@accrosoft-ltd/accro-multi-level-dropdown';

const Navbar = ({ props, websiteNavData }) => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const ReturnChildNavItems = ({ WebsiteNavData }) => {
    return (
      <>
        {WebsiteNavData && WebsiteNavData.length > 0 ? (
          WebsiteNavData.map((item, i) => (
            <Dropdown
              menuClassName="dropdown-child-nav"
              wrapperClassName="dropdown-wrapper-nav"
              buttonClassName="dropdown-parentLink link-item"
              className="dropdown-child-nav"
              isActive={true}
              title={item.title}
              onClick={() =>
                navigate(
                  item.parentSlug ? item.parentSlug + item.slug : item.slug
                )
              }
            >
              <RenderChildLinks item={item} i={i} />
            </Dropdown>
          ))
        ) : (
          <li className="loading-nav"></li>
        )}
      </>
    );
  };

  const RenderChildLinks = ({ item }) => {
    return (
      <>
        {item.children && item.children.length > 0 && (
          <>
            {item.children.map((child, i) => (
              <RenderChildLinkHTML child={child} />
            ))}
          </>
        )}
      </>
    );
  };

  const RenderChildLinkHTML = ({ child, i }) => {
    return (
      <Dropdown.Item
        className="dropdown-item link"
        key={i}
        onClick={() =>
          navigate(
            child.parentSlug ? child.parentSlug + child.slug : child.slug
          )
        }
      >
        {child.children && <span className="fas fa-angle-right"></span>}
        <div>
          <span>{child.title}</span>
        </div>

        {child.children && child.children.length > 0 && (
          <Dropdown.Submenu
            menuClassName="dropdown-submenu"
            className="dropdown-submenu"
          >
            {child.children.map((childtwo, itwo) => (
              <React.Fragment key={i + '_' + itwo}>
                <RenderChildLinkHTML child={childtwo} i={itwo} />
              </React.Fragment>
            ))}
          </Dropdown.Submenu>
        )}
      </Dropdown.Item>
    );
  };

  return (
    <div className="navbar top-links__wrapper py-2 d-flex">
      <div className="container d-flex justify-content-between top-links__wrapper__container">
        <a href={`/`} className="navbar-brand d-flex">
          {props.smartfeedCompanyData &&
            props.smartfeedCompanyData.CompanyName &&
            props.smartfeedCompanyData.Logo && (
              <img
                loading="lazy"
                src={props.smartfeedCompanyData.Logo}
                className="logo"
                alt={props.smartfeedCompanyData.CompanyName}
              />
            )}
        </a>
        <ul className="d-none d-lg-inline-flex mr-auto header__top-links header__top-links--job-vacancies">
          {props.siteConfig.showWebsiteNav && websiteNavData && (
            <ReturnChildNavItems WebsiteNavData={websiteNavData} />
          )}
          <li>
            <a
              href={
                props.useGroupVacsLink === true
                  ? '/group-vacancies/'
                  : `/vacancies/`
              }
            >
              Job Vacancies
            </a>
          </li>
          <li>
            <Link
              to={`${
                props.useGroupVacsLink === true
                  ? '/group-vacancies/'
                  : `/vacancies/`
              }saved/`}
            >
              Saved Jobs
            </Link>
          </li>
          {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
            <li>
              <a
                href={props.appGatewayURL.ApplicantGateway}
                target="_blank"
                rel="noreferrer"
              >
                Applicant Gateway
              </a>
            </li>
          )}
          <li>
            <Link
              to={
                props.useGroupVacsLink === true
                  ? '/job-alerts/group/'
                  : `/job-alerts/`
              }
              className="nav-action-sm"
              role="button"
            >
              Get job alerts
            </Link>
          </li>
        </ul>
        <div className="d-lg-none nav__hamburger_wrap">
          <div
            className={`nav_hamburger_wrap__inner ${
              showMobileNav === true ? 'toggled' : ''
            }`}
          >
            <div
              className="nav_hamburger_icon btn p-3"
              onClick={() => setShowMobileNav(!showMobileNav)}
              aria-hidden="true"
            >
              {!showMobileNav ? (
                <span className="fas fa-bars"></span>
              ) : (
                <span className="fas fa-times"></span>
              )}
            </div>
            <div className="nav_hamburger_wrap">
              <ul className="navigation">
                {props.siteConfig.showWebsiteNav && websiteNavData && (
                  <ReturnChildNavItems WebsiteNavData={websiteNavData} />
                )}
                <li>
                  <div className="link-item">
                    <a
                      href={
                        props.useGroupVacsLink === true
                          ? '/group-vacancies/'
                          : `/vacancies/`
                      }
                    >
                      Job Vacancies
                    </a>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <Link
                      to={`${
                        props.useGroupVacsLink === true
                          ? '/group-vacancies/'
                          : `/vacancies/`
                      }saved/`}
                    >
                      Saved Jobs
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <Link
                      to={
                        props.useGroupVacsLink === true
                          ? '/job-alerts/group/'
                          : `/job-alerts/`
                      }
                    >
                      Get job alerts
                    </Link>
                  </div>
                </li>
                {props.appGatewayURL && props.appGatewayURL.ApplicantGateway && (
                  <li>
                    <div className="link-item">
                      <a
                        href={props.appGatewayURL.ApplicantGateway}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Applicant Gateway
                      </a>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
